/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useNavigate } from "react-router-dom";
import CommonInput from "../../../components/common/commonInput";
import IMAGES from "../../../utils/constants/images";
import { useFormik } from "formik";
import { INITIALVALUES, VALIDATIONS } from "../../../utils/validation";
import CommoButton from "../../../components/common/commoButton";
import { RoutePath } from "../../../routes/routePath";
import { globalConfig } from "../../../utils/constants/globalConfig";
import { useChangePassword } from "../../../react-query/hooks/auth";
import { toast } from "react-custom-alert";

const ChangePassword = () => {
  let nav = useNavigate();
  let email: any = localStorage.getItem(globalConfig.COMPANY_EMAIL_NUMBER);

  // Getting verify otp mutation and loading state
  const { mutateAsync, isLoading, isSuccess, data } = useChangePassword();

  React.useEffect(() => {
    if (isSuccess === true) {
      toast.success(data?.message);
      nav(RoutePath.login.path);
      resetForm();
    }
  }, [isSuccess, data]);

  const [isActive, setIsActive] = React.useState({
    password: false,
    confirmPassword: false,
  });

  function handlePasswordVisible(data: string) {
    setIsActive((prev: any) => ({
      ...prev,
      [data]: !prev[data],
    }));
  }

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues: INITIALVALUES.changePasswordInitialValues,
    validationSchema: VALIDATIONS.changePasswordValidationSchema,
    onSubmit: async (values) => {
      try {
        await mutateAsync({
          email: email,
          newPassword: values?.confirmPassword,
        });
      } catch (error: any) {
        if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message);
        }
      }
    },
  });

  return (
    <div className="auth-container">
      <div className="form-blk">
        <div className="logo-main">
          <img src={IMAGES.mainLogo} alt="err" />
        </div>
        <div className="form-inner">
          <h1>Welcome Back!</h1>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <div className="position-relative">
                <CommonInput
                  type={isActive.password ? "text" : "password"}
                  errors={errors.password}
                  touched={touched.password}
                  name="password"
                  placeholder="Password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                />
                <div
                  onClick={() => handlePasswordVisible("password")}
                  className="eye-blk"
                >
                  {isActive.password ? (
                    <img src={IMAGES.eyeOpen} alt="eyeOpen" />
                  ) : (
                    <img src={IMAGES.eyeClose} alt="eyeClose" />
                  )}
                </div>
              </div>
            </div>
            <div className="mb-2">
              <div className="position-relative">
                <CommonInput
                  type={isActive.confirmPassword ? "text" : "password"}
                  errors={errors.confirmPassword}
                  touched={touched.confirmPassword}
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.confirmPassword}
                />
                <div
                  onClick={() => handlePasswordVisible("confirmPassword")}
                  className="eye-blk"
                >
                  {isActive.confirmPassword ? (
                    <img src={IMAGES.eyeOpen} alt="eyeOpen" />
                  ) : (
                    <img src={IMAGES.eyeClose} alt="eyeClose" />
                  )}
                </div>
              </div>
            </div>
            <div className="btn-blk">
              <CommoButton
                btnName="Save"
                disabled={isLoading}
                loading={isLoading}
              />
            </div>
          </form>
        </div>
      </div>
      <div className="img-blk">
        <img src={IMAGES.mainTheme} alt="" />
      </div>
    </div>
  );
};

export default ChangePassword;
