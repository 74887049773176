/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import IMAGES from "../../../utils/constants/images";
import { useLocation, useNavigate } from "react-router-dom";
import { RoutePath } from "../../../routes/routePath";
import { IPofileDetail } from "../../../utils/interface";
import PhoneInput from "react-phone-input-2";

const ProfileDetail = () => {
  let nav = useNavigate();
  let location = useLocation();
  const getData: IPofileDetail = location?.state?.data;

  return (
    <div className="container common-section p-5 position-relative">
      <div className="row">
        <div className="col-12 title-btn-blk">
          <h1 className="company-title-txt">Profile</h1>
          <button
            className="edit-btn"
            onClick={() =>
              nav(RoutePath.editProfileDetail.path, {
                state: {
                  data: getData,
                },
              })
            }
          >
            Edit Profile
          </button>
        </div>
      </div>
      <div className="cover-img-blk">
        {getData?.coverImage && (
          <img
            className="cover-img"
            alt=""
            src={
              getData?.coverImage
                ? getData?.coverImage
                : "https://www.cssoftsolutions.com/wp-content/themes/cssoft/assets/image/banner01.jpg"
            }
          />
        )}
        <div className="img-icon-blk">
          {getData?.profileImage && (
            <img
              className="profile-img"
              alt=""
              src={
                getData?.profileImage
                  ? getData?.profileImage
                  : IMAGES.defaultImage
              }
            />
          )}
        </div>
      </div>
      <div className="row gx-5 gy-3 justify-content-center mt-4">
        <div className="col-sm-6">
          <input
            value={getData?.name}
            readOnly
            className="form-control common-input"
          />
        </div>
        <div className="col-sm-6">
          <input
            value={getData?.industryType}
            readOnly
            className="form-control common-input"
          />
        </div>
        <div className="col-sm-6">
          <input
            value={getData?.companyType}
            readOnly
            className="form-control common-input"
          />
        </div>
        <div className="col-sm-6">
          <input
            value={getData?.profile_address}
            readOnly
            className="form-control common-input"
          />
        </div>
        <div className="col-sm-6">
          <input
            value={getData?.companySize}
            readOnly
            className="form-control common-input"
          />
        </div>
        <div className="col-sm-6">
          <input
            value={getData?.yearFounded}
            readOnly
            className="form-control common-input"
          />
        </div>
        <div className="col-sm-6">
          <input
            value={getData?.pinCode || "---"}
            readOnly
            className="form-control common-input"
          />
        </div>
        <div className="col-sm-6">
          <PhoneInput
            disabled
            containerClass=" form-control common-input"
            value={`${getData.countryCode}${getData.phoneNumber}`}
          />
        </div>
        <div className="col-sm-6">
          <input
            value={getData?.email}
            readOnly
            className="form-control common-input"
          />
        </div>
        <div className="col-sm-6">
          <a
            target="_blank"
            href={
              getData?.website.includes("https://")
                ? getData?.website
                : `https://${getData?.website}`
            }
            className="form-control common-input link text-truncate"
          >
            {getData?.website}
          </a>
        </div>

        <div className="col-sm-6">
          {!getData?.socialLink ? (
            <input
              value={"---"}
              readOnly
              className="form-control common-input"
            />
          ) : (
            <a
              target="_blank"
              href={
                getData?.socialLink.includes("https://")
                  ? getData?.socialLink
                  : `https://${getData?.socialLink}`
              }
              className="form-control common-input link text-truncate"
            >
              {getData?.socialLink}
            </a>
          )}
        </div>
        <div className="col-sm-6">
          <textarea
            className="form-control common-input"
            value={getData.description}
            readOnly
          />
        </div>
      </div>
    </div>
  );
};

export default ProfileDetail;
