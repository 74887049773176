import React from "react";
import {
  useGetDocsList,
  useDeleteDoc,
  useUploadDocument,
} from "../../../react-query/hooks/document";
import { toast } from "react-custom-alert";
import { ClipLoader } from "react-spinners";

import CustomAlert from "../../../components/common/alert";
import Swal from "sweetalert2";
import DoumentlistTable from "../../../components/screens/documentManagement/doumentlistTable";

const DocumentManagement = () => {
  const [documentFile, setDocumentFile] = React.useState<any>([]);
  const fileInputRef = React.useRef<any>(null);

  const { mutateAsync, isSuccess, isLoading } = useUploadDocument();
  const { mutateAsync: deleteDocMutateAsync, isSuccess: deleteDocSuccess } =
    useDeleteDoc();
  const { data: getDocsList } = useGetDocsList();

  // handle file select when upload docs
  function handleImageChange(e: React.ChangeEvent<HTMLInputElement>) {
    const file: any = e.target.files;
    setDocumentFile([...file]);
  }

  // handle file select from input ref
  function handleSelectFromLibrary() {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  }

  // Remove file by index
  function handleRemoveFile(index: number) {
    const updatedFiles = documentFile.filter(
      (_: any, i: number) => i !== index
    );
    setDocumentFile(updatedFiles);
  }

  // handle upload docs
  async function handleUploadDocs() {
    let formData = new FormData();
    documentFile?.map((docs: any) => formData.append("files", docs));
    try {
      await mutateAsync({ data: formData });
    } catch (error: any) {
      if (error?.response?.status === 400) {
        toast.error(error?.response?.data?.message);
      }
    }
  }

  // Handle delete request action with confirmation
  async function handleDocDelete(id: string) {
    CustomAlert({
      title: " Are you sure want to delete the doc?",
      confirmButtonText: "Delete",
      icon: "question",
      onConfirm: async () => {
        try {
          await deleteDocMutateAsync({
            docId: id,
          });
        } catch (error: any) {
          Swal.fire({
            title: "Error",
            text: error?.response?.data?.message,
            icon: "error",
            timer: 3000,
            showConfirmButton: false,
          });
        }
      },
    });
  }

  function renderHeaderTitle() {
    if (getDocsList?.isCompanyProfileVerified === "notRequested") {
      return <h2>Please submit your document for verification !</h2>;
    } else if (getDocsList?.isCompanyProfileVerified === "rejected") {
      return (
        <h2>Please check your documents and submit for verification again !</h2>
      );
    } else
      return (
        <p>
          Current Status :
          <span
            className={`text ${
              getDocsList?.isCompanyProfileVerified === "pending"
                ? "text-warning"
                : getDocsList?.isCompanyProfileVerified === "verified"
                ? "text-success"
                : getDocsList?.isCompanyProfileVerified === "unVerified" &&
                  "text-danger"
            }`}
          >
            {getDocsList?.isCompanyProfileVerified === "pending"
              ? "Waiting for aproval !"
              : getDocsList?.isCompanyProfileVerified === "verified"
              ? "Verified"
              : getDocsList?.isCompanyProfileVerified === "unVerified" &&
                "Un-Verified"}
          </span>
        </p>
      );
  }

  // Show success toast when a doc is successfully deleted
  React.useEffect(() => {
    if (deleteDocSuccess === true) {
      toast.success("Document deleted successfully");
    }
  }, [deleteDocSuccess]);

  React.useEffect(() => {
    if (isSuccess === true) {
      toast.success("Upload document successfully");
      setDocumentFile([]);
    }
  }, [isSuccess]);

  return (
    <div className="container common-section">
      <div className="row mt-5 g-4">
        <div className="col-12">
          <h1 className="company-title-txt">Document Management</h1>
          <div className="conntent-upload-blk mt-4">
            {renderHeaderTitle()}
            <div onClick={handleSelectFromLibrary} className="upload-inner-blk">
              <input
                multiple
                onChange={(e: any) => handleImageChange(e)}
                type="file"
                accept="*/*"
                className="d-none"
                ref={fileInputRef}
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                viewBox="0 0 512 512"
                id="upload"
              >
                <path d="M398.1 233.2c0-1.2.2-2.4.2-3.6 0-65-51.8-117.6-115.7-117.6-46.1 0-85.7 27.4-104.3 67-8.1-4.1-17.2-6.5-26.8-6.5-29.5 0-54.1 21.9-58.8 50.5C57.3 235.2 32 269.1 32 309c0 50.2 40.1 91 89.5 91H224v-80h-48.2l80.2-83.7 80.2 83.6H288v80h110.3c45.2 0 81.7-37.5 81.7-83.4 0-45.9-36.7-83.2-81.9-83.3z"></path>
              </svg>
              <label>Upload Document</label>
            </div>
          </div>
          {documentFile && documentFile.length !== 0 ? (
            <div className="tabcontent mb-5">
              <div className="row">
                <div className="col-12">
                  <div className="table-blk table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="text-start fs-5 fw-bold">
                            Document Name
                          </th>
                          <th className=" fs-5 fw-bold">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {documentFile?.map((item: any, index: number) => {
                          return (
                            <tr key={index}>
                              <td className="text-start">{item?.name}</td>
                              <td>
                                <span className="td-btn-box justify-content-center">
                                  <button
                                    onClick={() => handleRemoveFile(index)}
                                    className="btn-style common-secondary-btn "
                                  >
                                    Remove
                                  </button>
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className="d-flex justify-content-center mt-3">
                    <button
                      disabled={isLoading}
                      onClick={handleUploadDocs}
                      className="btn-style common-primary-btn w-25 p-2  fs-6"
                    >
                      {isLoading ? (
                        <ClipLoader size={13} color="#000000" />
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <DoumentlistTable
              handleDelete={(id) => handleDocDelete(id)}
              data={getDocsList?.docs}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default DocumentManagement;
