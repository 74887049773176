import * as Yup from "yup";

// initialValues
const loginInitialValues = {
  email: "",
  password: "",
};

const registerInitialValues = {
  name: "",
  email: "",
  countryCode: "",
  phoneNumber: "",
  password: "",
  confirmPassword: "",
};

// validation schema
const loginValidationSchema = Yup.object().shape({
  email: Yup.string()
    .matches(/^(?:\d+|\S+@\S+\.\S+)$/, "Please enter a valid email*")
    .required("Email/Mobile number is required*"),
  password: Yup.string()
    .min(5, "Password must be at least 5 characters")
    .max(15, "Password must be at most 15 characters")
    .required("Password is required*"),
});

const registerValidationSchema = Yup.object().shape({
  name: Yup.string().required("Full name is required*"),
  email: Yup.string().email().required("Email is required*"),
  phoneNumber: Yup.string().required("Mobile number is required*"),
  countryCode: Yup.string().required("Country code is required*"),
  password: Yup.string().min(5).max(15).required("Password is required*"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password")], "Password must match*")
    .min(5)
    .max(15)
    .required("Confirm your password*"),
});

export {
  loginInitialValues,
  registerInitialValues,
  loginValidationSchema,
  registerValidationSchema,
};
