/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import IMAGES from "../../../utils/constants/images";
import CommoButton from "../../../components/common/commoButton";
import CommonInput from "../../../components/common/commonInput";
import { useFormik } from "formik";
import { INITIALVALUES, VALIDATIONS } from "../../../utils/validation";
import { useNavigate } from "react-router-dom";
import { RoutePath } from "../../../routes/routePath";
import { useVerifyEmail } from "../../../react-query/hooks/auth";
import { toast } from "react-custom-alert";
import { globalConfig } from "../../../utils/constants/globalConfig";

const VerifyEmail = () => {
  let nav = useNavigate();

  // Getting send otp mutation and loading state
  const { mutateAsync, isLoading, isSuccess, data } = useVerifyEmail();

  React.useEffect(() => {
    if (isSuccess === true) {
      toast.success(data?.message);
      nav(RoutePath.verifyOtp.path);
      localStorage.setItem(globalConfig.COMPANY_EMAIL_NUMBER, values?.email);
      resetForm();
    }
  }, [isSuccess, data]);

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues: INITIALVALUES.verifyEmailInitialValues,
    validationSchema: VALIDATIONS.verifyEmailValidationSchema,
    onSubmit: async (values) => {
      try {
        await mutateAsync(values);
      } catch (error: any) {
        if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message);
        }
      }
    },
  });
  return (
    <div className="auth-container">
      <div className="form-blk">
        <div className="logo-main">
          <img src={IMAGES.mainLogo} alt="err" />
        </div>
        <div className="form-inner">
          <h1>Verify Email/Mobile Number!</h1>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <CommonInput
                errors={errors.email}
                touched={touched.email}
                name="email"
                placeholder="Email/Mobile Number"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
              />
            </div>
            <div className="btn-blk">
              <CommoButton
                btnName="Send"
                disabled={isLoading}
                loading={isLoading}
              />
            </div>
          </form>
        </div>
      </div>
      <div className="img-blk">
        <img src={IMAGES.mainTheme} alt="" />
      </div>
    </div>
  );
};

export default VerifyEmail;
