import * as Yup from "yup";

// initialValues
const verifyEmailInitialValues = {
  email: "",
};

const changePasswordInitialValues = {
  password: "",
  confirmPassword: "",
};

// validation schema
const verifyEmailValidationSchema = Yup.object().shape({
  email: Yup.string()
    .matches(/^(?:\d+|\S+@\S+\.\S+)$/, "Please enter a valid email*")
    .required("Email/Mobile number is required*"),
});

const changePasswordValidationSchema = Yup.object().shape({
  password: Yup.string().min(5).max(15).required("Password is required*"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password")], "Password must match*")
    .min(5)
    .max(15)
    .required("Confirm your password*"),
});

export {
  verifyEmailInitialValues,
  changePasswordInitialValues,
  verifyEmailValidationSchema,
  changePasswordValidationSchema,
};
