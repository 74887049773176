import React from "react";

const CustomIndustryDropdown = ({ options, selectedValue, onSelect }: any) => {
  // State for managing whether the dropdown is open or closed
  const [isOpen, setIsOpen] = React.useState(false);

  // State for managing the search input value
  const [serachIndustry, setSearchIndustry] = React.useState("");

  // Reference for detecting clicks outside of the dropdown
  const ref = React.useRef<HTMLDivElement>(null);

  // Effect to handle closing the dropdown when clicking outside
  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };
    // Add event listener for mousedown to detect outside clicks
    document.addEventListener("mousedown", handleClickOutside);
    // Cleanup event listener when component unmounts
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  // Memoized function to filter options based on the search input
  const filteredOptions = React.useMemo(() => {
    if (!serachIndustry) return options;
    return options?.filter((option: any) =>
      option?.toLowerCase().includes(serachIndustry?.toLowerCase())
    );
  }, [serachIndustry, options]);

  return (
    <div className="custom-dropdown form-select common-input" ref={ref}>
      <div
        className={`dropdown-header ${isOpen ? "open" : ""}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        {selectedValue || "Select Industry"}
      </div>
      {isOpen && (
        <div className="dropdown-body">
          <input
            type="text"
            placeholder="Search Industry"
            className="search-input"
            value={serachIndustry}
            onChange={(e) => setSearchIndustry(e.target.value)}
          />
          <ul className="dropdown-list">
            {filteredOptions?.length > 0 ? (
              filteredOptions.map((option: any, index: number) => (
                <li
                  className="dropdown-item"
                  key={index}
                  onClick={() => {
                    onSelect(option);
                    setIsOpen(false);
                  }}
                >
                  {option}
                </li>
              ))
            ) : (
              <li className="dropdown-item no-results">No results found</li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};
export default CustomIndustryDropdown;
