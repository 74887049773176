/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import IMAGES from "../../../utils/constants/images";
import { useLocation, useNavigate } from "react-router-dom";
import { INITIALVALUES, VALIDATIONS } from "../../../utils/validation";
import { useFormik } from "formik";
import CommonInput from "../../../components/common/commonInput";
import { numberOnly } from "../../../utils/helpers";
import {
  useCreateCompanyProfile,
  useGetIndustryList,
} from "../../../react-query/hooks/profile";
import CommoButton from "../../../components/common/commoButton";
import { toast } from "react-custom-alert";
import { useAppContext } from "../../../context/authContext";
import { globalConfig } from "../../../utils/constants/globalConfig";
import PhoneInput from "react-phone-input-2";
import CustomIndustryDropdown from "../../../components/screens/profile/customIndustryDropdown";
import CustomCompanyTypeDropdown from "../../../components/screens/profile/cutomCompanyTypeDropDown";

const CreateProfile = () => {
  // getting data from state
  let location = useLocation();

  // Getting setIsAuthenticated from app context
  const { setIsAuthenticated } = useAppContext();

  const fileInputRef = React.useRef<any>(null);
  const coverFileInputRef = React.useRef<any>(null);

  let nav = useNavigate();

  const [profileImage, setProfileImage] = React.useState<any>(null);
  const [coverImage, setCoverImage] = React.useState<any>(null);
  const companyTypeOptions = ["Private", "Non Profit", "Government"];

  const handleCompanyTypeSelect = (selectedType: string) => {
    setFieldValue("companyType", selectedType);
  };

  // Getting create profile mutation and loading state
  const { mutateAsync, isLoading, isSuccess, data } = useCreateCompanyProfile();
  const { data: getIndustryList } = useGetIndustryList({
    search: "",
  });

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: INITIALVALUES.companyProfileInitialValues,
    validationSchema: VALIDATIONS.companyProfileValidationSchema,
    onSubmit: async (values) => {
      let fromData = new FormData();
      try {
        fromData.append("name", values.name);
        fromData.append("profile_address", values.address);
        fromData.append("description", values.description);
        fromData.append("pinCode", values.pinCode);
        fromData.append("email", values.email);
        fromData.append("website", values.website);
        fromData.append("phoneNumber", values.phoneNumber);
        fromData.append("countryCode", values.countryCode);
        fromData.append("socialLink", values.socialLink);
        fromData.append("profileImage", profileImage);
        fromData.append("coverImage", coverImage);
        fromData.append("companySize", values.companySize);
        fromData.append("companyType", values.companyType);
        fromData.append("industryType", values.industryType);
        fromData.append("yearFounded", values.yearFounded);
        await mutateAsync({ data: fromData });
      } catch (error: any) {
        if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message);
        }
      }
    },
  });

  // handle image select from input ref
  function handleSelectFromLibrary(value: string) {
    if (value === "profileImage") {
      if (fileInputRef.current) {
        fileInputRef.current.click();
      }
    } else {
      if (coverFileInputRef.current) {
        coverFileInputRef.current.click();
      }
    }
  }

  // handle image select when create profile
  function handleImageChange(
    e: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) {
    const file = e.target.files?.[0];
    if (value === "profileImage") {
      setProfileImage(file);
      setFieldValue("profileImage", file);
    } else {
      setCoverImage(file);
      setFieldValue("coverImage", file);
    }
  }

  React.useEffect(() => {
    if (isSuccess === true) {
      toast.success("Profile created successfully");
      localStorage.setItem(
        globalConfig.CARDIFY_PROFILE_TOKEN,
        data?.data?.token
      );
      setIsAuthenticated(true);
      resetForm();
    }
  }, [isSuccess]);

  React.useEffect(() => {
    if (location?.state) {
      setFieldValue("name", location?.state?.name);
      setFieldValue("phoneNumber", location?.state?.phoneNumber);
      setFieldValue("countryCode", location?.state?.countryCode);
      setFieldValue("email", location?.state?.email);
    }
  }, [location?.state]);

  return (
    <div className="container p-5 position-relative">
      <button className="back-btn" onClick={() => nav(-1)}>
        Back
      </button>
      <div className="row">
        <div className="col-12">
          <h1 className="company-title-txt">Create Company Profile</h1>
        </div>
      </div>

      <div className="cover-img-blk">
        {coverImage ? (
          <img
            className="profile-img"
            src={URL.createObjectURL(coverImage)}
            alt="profileImg"
          />
        ) : (
          <img
            className="cover-img"
            src="https://www.cssoftsolutions.com/wp-content/themes/cssoft/assets/image/banner01.jpg"
            alt="cover-img"
          />
        )}
        <div className="cover-img-show">
          <input
            onChange={(e: any) => handleImageChange(e, "coverImage")}
            type="file"
            accept="image/*"
            className="d-none"
            ref={coverFileInputRef}
          />
          <label
            onClick={() => handleSelectFromLibrary("coverImage")}
            className="flex-column d-flex justify-content-center align-items-center"
          >
            Upload cover image
            <img width={25} src={IMAGES.editIcon} alt="edit" />
          </label>
        </div>
        {errors.coverImage && touched.coverImage && (
          <p
            className="error-text"
            style={{ position: "absolute", right: "-13px", bottom: "-30px" }}
          >
            {errors.coverImage}
          </p>
        )}
        <div className="img-icon-blk">
          {profileImage ? (
            <img
              className="profile-img"
              src={
                profileImage
                  ? URL.createObjectURL(profileImage)
                  : IMAGES.defaultImage
              }
              alt="profileImg"
            />
          ) : (
            <img
              className="profile-img"
              alt="profileImg"
              src={IMAGES.defaultImage}
            />
          )}
          <span className="icon-blk">
            <input
              onChange={(e: any) => handleImageChange(e, "profileImage")}
              type="file"
              accept="image/*"
              className="d-none"
              ref={fileInputRef}
            />
            <label onClick={() => handleSelectFromLibrary("profileImage")}>
              <img
                className="img-inner-icon"
                src={IMAGES.editIcon}
                alt="edit"
              />
            </label>
          </span>
        </div>
        {errors.profileImage && touched.profileImage && (
          <p className="error-text mt-4">{errors.profileImage}</p>
        )}
      </div>

      <form onSubmit={handleSubmit}>
        <div className="row gx-5 gy-3 justify-content-center mt-4">
          <div className="col-sm-6">
            <CommonInput
              errors={errors.name}
              touched={touched.name}
              name="name"
              placeholder="Company Name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
            />
          </div>
          <div className="col-sm-6">
            <CustomIndustryDropdown
              options={getIndustryList?.data}
              selectedValue={values.industryType}
              onSelect={(industry: string) =>
                setFieldValue("industryType", industry)
              }
            />
            {errors.industryType && touched.industryType && (
              <p className="error-text ">{errors.industryType}</p>
            )}
          </div>
          <div className="col-sm-6">
            <CustomCompanyTypeDropdown
              options={companyTypeOptions}
              selectedValue={values.companyType}
              onSelect={handleCompanyTypeSelect}
            />
            {errors.companyType && touched.companyType && (
              <p className="error-text ">{errors.companyType}</p>
            )}
          </div>
          <div className="col-sm-6">
            <CommonInput
              errors={errors.address}
              name="address"
              touched={touched.address}
              placeholder="Address"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.address}
            />
          </div>
          <div className="col-sm-6">
            <CommonInput
              errors={errors.companySize}
              name="companySize"
              touched={touched.companySize}
              placeholder="Company Size"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.companySize}
            />
          </div>
          <div className="col-sm-6">
            <CommonInput
              errors={errors.yearFounded}
              name="yearFounded"
              touched={touched.yearFounded}
              placeholder="Founded Year"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.yearFounded}
            />
          </div>
          <div className="col-sm-6">
            <CommonInput
              errors={errors.pinCode}
              max={6}
              onKeyPress={numberOnly}
              touched={touched.pinCode}
              name="pinCode"
              placeholder="Pin Code"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.pinCode}
            />
          </div>
          <div className="col-sm-6">
            <PhoneInput
              containerClass=" form-control common-input"
              placeholder="Mobile Number "
              country={"in"}
              value={`${values.countryCode}${values.phoneNumber}`}
              onChange={(value, data: any) => {
                const rawPhone = value
                  .replace(/[^0-9]+/g, "")
                  .slice(data?.dialCode?.length);
                setFieldValue("phoneNumber", rawPhone);
                setFieldValue("countryCode", data.dialCode);
              }}
            />
            {touched.phoneNumber && errors.phoneNumber && (
              <p className="error-text">{errors.phoneNumber}</p>
            )}
          </div>
          <div className="col-sm-6">
            <CommonInput
              type="email"
              errors={errors.email}
              touched={touched.email}
              name="email"
              placeholder="Email Address"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
            />
          </div>
          <div className="col-sm-6">
            <CommonInput
              name="website"
              errors={errors.website}
              touched={touched.website}
              placeholder="Website"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.website}
            />
          </div>
          <div className="col-sm-6">
            <CommonInput
              errors={errors.socialLink}
              touched={touched.socialLink}
              name="socialLink"
              placeholder="Social Link"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.socialLink}
            />
          </div>
          <div className="col-sm-6">
            <textarea
              rows={3}
              className="form-control common-input"
              name="description"
              placeholder="Enter Description Here..."
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.description}
            />
            {errors.description && touched.description && (
              <p className="error-text">{errors.description}</p>
            )}
          </div>
        </div>
        <div className="col">
          <div className="save-btn-blk">
            <CommoButton
              disabled={isLoading}
              btnName="Save"
              loading={isLoading}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateProfile;
