import Swal from "sweetalert2";
import CustomAlert from "../../../components/common/alert";
import DoumentlistTable from "../../../components/screens/documentManagement/doumentlistTable";
import {
  useDeleteDoc,
  useGetDocsList,
} from "../../../react-query/hooks/document";
import React from "react";
import { toast } from "react-custom-alert";

const ProfileManagement = () => {
  const { data: getDocsList } = useGetDocsList();
  const { mutateAsync: deleteDocMutateAsync, isSuccess: deleteDocSuccess } =
    useDeleteDoc();

  // Handle delete request action with confirmation
  async function handleDocDelete(id: string) {
    CustomAlert({
      title: " Are you sure want to delete the doc?",
      confirmButtonText: "Delete",
      icon: "question",
      onConfirm: async () => {
        try {
          await deleteDocMutateAsync({
            docId: id,
          });
        } catch (error: any) {
          Swal.fire({
            title: "Error",
            text: error?.response?.data?.message,
            icon: "error",
            timer: 3000,
            showConfirmButton: false,
          });
        }
      },
    });
  }
  // Show success toast when a doc is successfully deleted
  React.useEffect(() => {
    if (deleteDocSuccess === true) {
      toast.success("Document deleted successfully");
    }
  }, [deleteDocSuccess]);
  return (
    <div className="container common-section">
      <div className="row mt-5 g-4">
        <div className="col-12">
          <h1 className="company-title-txt">Profile Management</h1>
          <div className=" mt-4">
            <DoumentlistTable
              handleDelete={(id) => handleDocDelete(id)}
              data={getDocsList?.docs}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileManagement;
