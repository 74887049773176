import { IBtnProps } from "../../utils/interface";
import { DotLoader } from "react-spinners";

const CommoButton = ({
  btnName,
  onClick,
  loading,
  type,
  disabled,
}: IBtnProps) => {
  return (
    <button type={type} onClick={onClick} disabled={disabled}>
      {loading ? <DotLoader size={33} color="#fff" /> : btnName}
    </button>
  );
};

export default CommoButton;
