/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { IDocsProps } from "../../../utils/interface";

interface IProps {
  data: any;
  handleDelete: (id: string) => void;
}

const DoumentlistTable = ({ data, handleDelete }: IProps) => {
  if (!data || data?.length === 0) {
    return (
      <div className="row">
        <div className="col-12">
          <div className="not-found-blk vh-40">
            <h1>No document found. Please upload !</h1>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="tabcontent">
      <div className="row">
        <div className="col-12">
          <div className="table-blk table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th className="text-start fs-5 fw-bold">Document Name</th>
                  <th className=" fs-5 fw-bold">Action</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item: IDocsProps, index: number) => {
                  return (
                    <tr key={index}>
                      <td className="text-start">{item?.documentName}</td>
                      <td>
                        <span className="td-btn-box justify-content-center">
                          <button
                            onClick={() => window.open(item?.documentUrl)}
                            className="btn-style common-primary-btn "
                          >
                            View
                          </button>
                          <button
                            onClick={() => handleDelete(item?._id)}
                            className="btn-style common-secondary-btn "
                          >
                            Delete
                          </button>
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoumentlistTable;
