import React from "react";
import { IRequestProps } from "../../../utils/interface";
import TablePagination from "./tablePagination";
import { tableArray } from "../../../utils/seeds";

interface IProps {
  data: IRequestProps[];
  handleRequestReject: (id: string) => void;
  handleRequestAccept: (id: string) => void;
  handleRequestDelete: (id: string) => void;
  count: number;
  pageNumber: number;
  totalRecords: number;
  onPageChange: (page: number) => void;
  isFetching: boolean;
}

const TableList = ({
  data,
  handleRequestAccept,
  handleRequestDelete,
  handleRequestReject,
  totalRecords,
  count,
  pageNumber,
  onPageChange,
  isFetching,
}: IProps) => {
  if (!data || data?.length === 0) {
    return (
      <div className="row">
        <div className="col-12">
          <div className="not-found-blk">
            <h1>No request found!</h1>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="tabcontent">
      <div className="row">
        <div className="col-12">
          <div className="table-blk table-responsive">
            <table className="table">
              <thead>
                <tr>
                  {tableArray?.map((item, index) => (
                    <th key={index}>{item?.name}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data?.map((items: IRequestProps, index: number) => {
                  return (
                    <tr key={index}>
                      <td>
                        <span className="td-user-img">
                          <img src={items?.profileImage} alt="User" />
                        </span>
                      </td>
                      <td>{items?.name}</td>
                      <td>{items?.designation}</td>
                      <td>{items?.phoneNumber}</td>
                      <td>{items?.email}</td>
                      <td>
                        {items?.status === 0 ? (
                          <span className="td-btn-box">
                            <button
                              onClick={() => handleRequestReject(items?._id)}
                              className="btn-style common-secondary-btn"
                            >
                              Reject
                            </button>
                            <button
                              onClick={() => handleRequestAccept(items?._id)}
                              className="btn-style common-primary-btn"
                            >
                              Accept
                            </button>
                          </span>
                        ) : (
                          <button
                            onClick={() => handleRequestDelete(items?._id)}
                            className="btn-style common-secondary-btn"
                          >
                            Delete
                          </button>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <TablePagination
            count={count}
            pageNumber={pageNumber}
            totalRecords={totalRecords}
            onPageChange={onPageChange}
          />
        </div>
      </div>
    </div>
  );
};

export default TableList;
