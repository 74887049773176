import { loadStripe } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import { toast } from "react-custom-alert";
import Swal from "sweetalert2";
import CustomAlert from "../../../components/common/alert";
import SubscriptionPlans from "../../../components/screens/subscriptionPlans";
import {
  useCancelSubscription,
  useGetSubscriptionList,
  usePurschaseSubscription,
  useUpdatePurschaseSubscription,
} from "../../../react-query/hooks/subscription";
import { ISubscriptionProps } from "../../../utils/interface";
import SubscriptionPlansSkelton from "../../../components/skeltons/subscriptionPlansSkelton";

const SubscriptionPlan = () => {
  // Load Stripe with the published key from environment variables
  const stripePromise = loadStripe(
    `${process.env.REACT_APP_STRIPE_PULISHED_KEY}`
  );

  // State to track loading status
  const [isLoading, setIsloading] = useState<number | null>(null);

  // Fetch the subscription list
  const { data, isFetching } = useGetSubscriptionList();

  // Mutation for upgrading , purchase and cancel a subscription
  const { mutateAsync: purschaseSubscription } = usePurschaseSubscription();
  const { mutateAsync: upgradeSubscriptionPlan } =
    useUpdatePurschaseSubscription();
  const { mutateAsync: cancelSubscription, isSuccess } =
    useCancelSubscription();

  // Find the subscribed plan with a valid end date
  const subscribedPlan = data?.plans?.find(
    (item: ISubscriptionProps) => item.planEndDate && item.isSubscribed
  );
  const notExpiredPlan = data?.plans?.find(
    (item: ISubscriptionProps) => item.planEndDate
  );

  // Handle the Stripe payment process
  async function handlePayment(data: ISubscriptionProps, index: number) {
    setIsloading(index);
    try {
      let response: any;
      const stripe: any = await stripePromise;
      if (subscribedPlan) {
        response = await upgradeSubscriptionPlan({
          priceId: data?.id,
          subscriptionId: data?.stripeSubscriptionId,
        });
      } else {
        response = await purschaseSubscription({
          priceId: data?.id,
        });
      }
      const { sessionId } = response;
      await stripe.redirectToCheckout({ sessionId });
    } catch (error: any) {
      toast.error("Something went wrong");
    } finally {
      setIsloading(null);
    }
  }

  // Handle canceling a subscription
  async function handleCancleSubscription(id: string) {
    CustomAlert({
      title: "Are you sure want to cancle subscription ?",
      confirmButtonText: "Confirm",
      icon: "question",
      onConfirm: async () => {
        try {
          await cancelSubscription({ subscriptionId: id });
        } catch (error: any) {
          Swal.fire({
            title: "Error",
            text: error?.response?.data?.message,
            icon: "error",
            timer: 1000,
            showConfirmButton: false,
          });
        }
      },
    });
  }

  // function for retreiving list of subscription plans
  function renderPlans() {
    return data?.plans && data?.plans?.length === 0 ? (
      <div className="plan-txt-blk">
        <p>No subscription plans available!</p>
      </div>
    ) : (
      data?.plans?.map((item: ISubscriptionProps, index: number) => {
        return (
          <SubscriptionPlans
            isLoading={isLoading === index}
            disabled={
              notExpiredPlan?.planEndDate &&
              notExpiredPlan?.planAmount > item?.planAmount
            }
            handleCancleSubscription={() =>
              handleCancleSubscription(item?.stripeSubscriptionId)
            }
            handlePayment={() => handlePayment(item, index)}
            isSubscribed={item?.isSubscribed}
            endDate={item?.planEndDate}
            planAmount={item?.planAmount / 100 || item.unit_amount / 100}
            planDescription={
              item?.planDescription || item.metadata?.planDescription
            }
            planTitle={item?.planTitle || item?.metadata?.planTitle}
            data={item}
            metaData={item?.planMetaData}
            isExpired={
              item?.planEndDate
                ? Date.now() > new Date(item?.planEndDate).getTime()
                : false
            }
          />
        );
      })
    );
  }

  // Effect to show a success toast when the subscription cancel
  useEffect(() => {
    if (isSuccess === true) toast.success("Subscription Cancelled");
  }, [isSuccess]);

  return (
    <div className="container-fluid common-section px-5">
      <div className="row mt-5 g-4 mb-3 justify-content-center">
        <div className="col-12">
          <h1 className="company-title-txt mb-sm-5 mb-3">
            Flexible pricing plan for your startup
          </h1>
        </div>
        {isFetching ? (
          <div className="row g-3">
            {[1, 2, 3].map((index) => (
              <SubscriptionPlansSkelton />
            ))}
          </div>
        ) : (
          renderPlans()
        )}
      </div>
    </div>
  );
};

export default SubscriptionPlan;
