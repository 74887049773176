/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import IMAGES from "../../../utils/constants/images";
import { useNavigate } from "react-router-dom";
import { RoutePath } from "../../../routes/routePath";
import { INITIALVALUES, VALIDATIONS } from "../../../utils/validation";
import { useFormik } from "formik";
import CommonInput from "../../../components/common/commonInput";
import { useCompanyRegister } from "../../../react-query/hooks/auth";
import { globalConfig } from "../../../utils/constants/globalConfig";
import { toast } from "react-custom-alert";
import CommoButton from "../../../components/common/commoButton";
import PhoneInput from "react-phone-input-2";

const SignUp = () => {
  let nav = useNavigate();

  const [isActive, setIsActive] = React.useState({
    password: false,
    confirmPassword: false,
  });

  // Getting register mutation and loading state
  const { mutateAsync, isLoading, isSuccess, data } = useCompanyRegister();

  React.useEffect(() => {
    if (isSuccess === true) {
      toast.success("Register successfully");
      localStorage.setItem(
        globalConfig.CARDIFY_REGISTER_TOKEN,
        data?.data.token
      );
      nav(RoutePath.createProfile.path, { state: values });
      resetForm();
    }
  }, [isSuccess]);

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: INITIALVALUES.registerInitialValues,
    validationSchema: VALIDATIONS.registerValidationSchema,
    onSubmit: async (values) => {
      try {
        await mutateAsync(values);
      } catch (error: any) {
        if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message);
        }
      }
    },
  });

  function handlePasswordVisible(data: string) {
    setIsActive((prev: any) => ({
      ...prev,
      [data]: !prev[data],
    }));
  }

  return (
    <div className="auth-container">
      <div className="form-blk">
        <div className="logo-main">
          <img src={IMAGES.mainLogo} alt="err" />
        </div>
        <div className="form-inner">
          <h1>Sign Up</h1>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <CommonInput
                type="text"
                errors={errors.name}
                touched={touched.name}
                name="name"
                placeholder="Full Name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
              />
            </div>
            <div className="mb-3">
              <CommonInput
                type="email"
                errors={errors.email}
                touched={touched.email}
                name="email"
                placeholder="Email Address"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
              />
            </div>
            <div className="mb-3">
              <PhoneInput
                containerClass=" form-control common-input"
                placeholder="Mobile Number "
                country={"in"}
                value={`${values.countryCode}${values.phoneNumber}`}
                onChange={(value, data: any) => {
                  const rawPhone = value
                    .replace(/[^0-9]+/g, "")
                    .slice(data?.dialCode?.length);
                  setFieldValue("phoneNumber", rawPhone);
                  setFieldValue("countryCode", data.dialCode);
                }}
              />
              {touched.phoneNumber && errors.phoneNumber && (
                <p className="error-text">{errors.phoneNumber}</p>
              )}
            </div>
            <div className="mb-3">
              <div className="position-relative">
                <CommonInput
                  type={isActive.password ? "text" : "password"}
                  errors={errors.password}
                  touched={touched.password}
                  name="password"
                  placeholder="Password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                />
                <div
                  onClick={() => handlePasswordVisible("password")}
                  className="eye-blk"
                >
                  {isActive.password ? (
                    <img src={IMAGES.eyeOpen} alt="eyeOpen" />
                  ) : (
                    <img src={IMAGES.eyeClose} alt="eyeClose" />
                  )}
                </div>
              </div>
            </div>
            <div className="mb-2">
              <div className="position-relative">
                <CommonInput
                  type={isActive.confirmPassword ? "text" : "password"}
                  errors={errors.confirmPassword}
                  touched={touched.confirmPassword}
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.confirmPassword}
                />
                <div
                  onClick={() => handlePasswordVisible("confirmPassword")}
                  className="eye-blk"
                >
                  {isActive.confirmPassword ? (
                    <img src={IMAGES.eyeOpen} alt="eyeOpen" />
                  ) : (
                    <img src={IMAGES.eyeClose} alt="eyeClose" />
                  )}
                </div>
              </div>
            </div>
            <div className="btn-blk">
              <CommoButton
                disabled={isLoading}
                btnName="Sign up"
                loading={isLoading}
              />
              <p>
                Already have an account?{" "}
                <span onClick={() => nav(RoutePath.login.path)}>Login</span>
              </p>
            </div>
          </form>
        </div>
      </div>
      <div className="img-blk">
        <img src={IMAGES.mainTheme} alt="" />
      </div>
    </div>
  );
};

export default SignUp;
